import React from "react"
import SEO from "../components/seo"
import Layout from "../containers/layout/layout"
import Header from "../containers/layout/header"
import Footer from "../containers/layout/footer"
import { useStaticQuery, graphql } from "gatsby"
import PageTitleArea from "../containers/page-areas/page-title-area"
import PageBannerArea from "../containers/page-areas/page-banner-area"
import PageContentArea from "../containers/page-areas/page-content-area"
import Heading from "../components/shared/heading"
import Text from "../components/shared/text"
import { Row, Col } from "reactstrap"
import { FaQuoteLeft } from "react-icons/fa"
import {
  List,
  ContentBottom,
  Quote,
} from "../containers/page-areas/page-content-area/page-content-area.stc.js"

import InstagramWrap from "../containers/global/instagram"

const AboutUsPage = props => {
  const {
    // topHeadingCSS,
    headingCSS,
    textCSS,
    listHeadingCSS,
    bottomHeadingCSS,
  } = props
  const { file } = useStaticQuery(
    graphql`
      query {
        file(name: { eq: "envyforge-scale" }) {
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  return (
    <Layout>
      <SEO title="About Us | Envy Forge" />
      <Header />
      <div className="main-content">
        <PageTitleArea
          title="About Envy Forge"
          tagline="Let's work together to get the right products to market and grow, faster!"
        />
        <PageBannerArea
          bannerimage={file.childImageSharp.fluid}
          banneralt="About Us"
        />
        <PageContentArea heading="How you can contribute">
          <Heading {...headingCSS}>What we're about</Heading>
          <Text {...textCSS}>
            We’re a group of like-minded individuals brought together by a
            shared set of values. We are idealistic and opinionated, but still
            empathetic and pragmatic. We’re teachers, creatives, engineers and
            exceptional professionals. We’re tinkerers, proud parents, coaches,
            Star Wars “fans” and beer drinkers. We are passionate about what we
            do and always present for what’s in front of us.
          </Text>
          <Text {...textCSS}>
            Our business is about more than just product development, value
            creation and the growth potential. It’s about building exceptional
            experiences and developing great relationships along the way.
          </Text>
          <Text {...textCSS}>
            More than anything, we love building great products that improve
            people’s everyday lives. We are more than a tech company — we’re a
            culture company — and we've shaped our practices around strong
            values acquired through years of experience. We hold each other
            accountable, we’re honest and we respect the roles and expertise
            that each team member brings to the table.
          </Text>
          <Text {...textCSS}>
            Our ironclad commitment is to understanding your business
            challenges, goals, and objectives through empathy and active
            listening so that, together, we can build exceptional experiences.
            If you are building a team, a product, or rationalizing a system or
            business process and want to engage Envy Forge to consult for your
            business or technology leadership teams, please reach out. We’re
            eager to meet you and bring you into our exciting world.
          </Text>
          <Row>
            <Col lg={6}>
              <Heading {...listHeadingCSS}>Services</Heading>
              <List>
                <li key={`left-list-1`}>CxO as a Service</li>
                <li key={`left-list-2`}>Value Creation</li>
                <li key={`left-list-3`}>Growth & Investment Readiness</li>
              </List>
            </Col>
            <Col lg={6}>
              <Heading {...listHeadingCSS}>Practices</Heading>
              <List>
                <li key={`left-right-1`}>Product Management</li>
                <li key={`left-right-2`}>Design</li>
                <li key={`left-right-3`}>Product Development</li>
                <li key={`left-right-4`}>Agile Practices</li>
                <li key={`left-right-5`}>Architecture</li>
              </List>
            </Col>
          </Row>
          <ContentBottom>
            <Heading {...bottomHeadingCSS}>How We Do It</Heading>
            <Text {...textCSS}>
              Our founder, Matt, is known for a particular phrase — an approach:
            </Text>
            <Quote>
              <FaQuoteLeft className="icon" />
              <h3>Listen. Disrupt. Learn.</h3>
              <footer>- Matt Ortiz</footer>
            </Quote>
            <Text {...textCSS}>
              It is a nod to his human-centered, agile and iterative perspective
              on complex problem-solving. But it could be mistaken as stating
              that we are, among other things, disruptive. This is its true
              meaning:
            </Text>
            <Text {...textCSS}>
              <blockquote>“Listen.”</blockquote>
              Refers to the approach of truly listening and empathizing with
              users – a human-centered perspective. We value customer input and
              feedback as early as possible over big up-front design.
            </Text>
            <Text {...textCSS}>
              <blockquote>“Disrupt.”</blockquote>
              Refers to the fact that if you engage us, then we are by
              definition working on disruptive change. We are experts in easing
              the pain of complex, disruptive-change. Customers trust us to get
              them home safely, to build great experiences, and to have a great
              time while we’re at it.
            </Text>
            <Text {...textCSS}>
              <blockquote>“Learn.”</blockquote>
              At first, it was “Adjust.”. However, he felt that was not the
              right approach. “Adjust.” assumes that we must continuously change
              (perhaps for its own sake), which is not always the case. So we
              must “Learn.” from our work, continuously, testing our assumptions
              and staying informed by customer desire and feedback. Then, if we
              determine a need, we can plan and adapt.
            </Text>
          </ContentBottom>
        </PageContentArea>
        <InstagramWrap />
      </div>
      <Footer />
    </Layout>
  )
}

AboutUsPage.defaultProps = {
  topHeadingCSS: {
    as: "h4",
    color: "secondaryColor",
    lineHeight: 1.375,
    mb: "17px",
  },
  headingCSS: {
    as: "h4",
    color: "secondaryColor",
    lineHeight: 1.375,
    mb: "17px",
  },
  textCSS: {
    mb: "26px",
  },
  listHeadingCSS: {
    as: "h5",
    mb: "14px",
  },
  bottomHeadingCSS: {
    as: "h4",
    mb: "24px",
  },
}

export default AboutUsPage
